<template>
  <div id="dashboard-content">
    <h4><b>Pengajuan ISBN</b></h4>
    <div v-if="$store.state.messageStatusISBN" class="alert alert-success" role="alert">
      Berhasil ditambahkan. <i class="fa fa-check"></i>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <small class="text-muted">Silahkan isi data dengan lengkap</small>
    <div class="mt-4 card border-0 shadow-sm p-4">
      <form @submit.prevent="updateISBN()" enctype="multipart/form-data" accept-charset="utf-8">
        <input type="hidden" name="csrf_test_name" value="44c8529798bd8316b2483295c17206d9" /><input type="hidden"
          name="status" value="draft" />
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Judul</label>
              <input v-model="isbn.judul" type="text" class="form-control" placeholder="Judul" required="required" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="m-0">Kepengarangan</label> <br>
              <small class="text-muted">Diisi dengan satu nama orang sesuai dengan perannya. Untuk menambahkan nama beserta peran lainnya, tekan tombol ( + )</small>
              <div v-for="(author, index) in authors" :key="index" class="mb-2 mt-2">
                <div class="row">
                  <div class="col-md-3">
                    <select v-model="author.role" class="form-control" @change="updateKepengarangan">
                      <option value="">Pilih Peran</option>
                      <option value="penulis">Penulis</option>
                      <option value="ilustrator">Ilustrator</option>
                      <option value="penerjemah">Penerjemah</option>
                      <option value="penyunting">Penyunting</option>
                      <option value="editor">Editor</option>
                    </select>
                  </div>
                  <div class="col-md-7">
                    <input type="text" v-model="author.value" class="form-control" placeholder="Nama" @input="updateKepengarangan">
                  </div>
                  <div class="col-md-2">
                    <button @click="removeAuthor(index)" type="button" class="btn btn-danger btn-sm" :disabled="authors.length === 1">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
              <small v-if="messageError.kepengarangan" class="text-danger d-block">{{ messageError.kepengarangan }}</small>
              <button @click="addAuthor" type="button" class="btn btn-sm btn-secondary mt-2">
                <i class="fa fa-plus"></i> Tambah Kepengarangan
              </button>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Sinopsis / Subjek</label>
              <textarea v-model="isbn.sinopsis" minlength="50" maxlength="1000" placeholder="Sinopsis / Subjek" rows="5" class="form-control" required></textarea>
              <small class="text-muted">Minimal 50 karakter, maksimal 1000 karakter ({{ isbn.sinopsis.length }}/1000)</small>
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group">
              <label>Distributor</label>
              <input v-model="isbn.distributor" type="text" class="form-control" placeholder="Distributor"
                required="required" />
              <div class="help-block with-errors"></div>
            </div>
          </div> -->
          <div class="col-md-6">
            <div class="form-group">
              <label>Edisi</label>
              <input v-model="isbn.edisi" type="number" min="1" class="form-control" placeholder="Edisi" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Seri</label>
              <input v-model="isbn.seri" type="text" class="form-control" placeholder="Seri" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Tahun Terbit</label>
              <input v-model="isbn.tahun_terbit" type="number" min="1" class="form-control" placeholder="Tahun Terbit"
                required="required" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Jumlah Halaman</label>
              <input v-model="isbn.jumlah_halaman" type="text" class="form-control" placeholder="Jumlah Halaman"
                required="required" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label>Tinggi Buku</label>
              <input v-model="isbn.tinggi_buku" type="number" min="1" class="form-control" placeholder="Tinggi Buku"
                required="required" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Dimensi</label>
              <input v-model="isbn.dimensi" type="text" class="form-control" placeholder="Dimensi" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Harga Buku</label>
              <input v-model="isbn.harga_buku" type="text" class="form-control" placeholder="Harga buku" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Bulan Terbit</label>
              <select id="month" v-model="isbn.bulan_terbit" class="form-control">
                <option value="01" selected>Januari</option>
                <option value="02">Februari</option>
                <option value="03">Maret</option>
                <option value="04">April</option>
                <option value="05">Mei</option>
                <option value="06">Juni</option>
                <option value="07">Juli</option>
                <option value="08">Agustus</option>
                <option value="09">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Desember</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tempat Terbit</label>
              <input v-model="isbn.tempat_terbit" type="text" class="form-control" placeholder="Tempat terbit" />
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Kelompok Pembaca</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kelompok_pembaca" value="1" type="radio" id="kelompokPembaca1"
                    name="kelompokPembaca" class="custom-control-input">
                  <label class="custom-control-label" for="kelompokPembaca1">Anak</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kelompok_pembaca" value="2" type="radio" id="kelompokPembaca2"
                    name="kelompokPembaca" class="custom-control-input">
                  <label class="custom-control-label" for="kelompokPembaca2">Dewasa</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kelompok_pembaca" value="3" type="radio" id="kelompokPembaca3"
                    name="kelompokPembaca" class="custom-control-input">
                  <label class="custom-control-label" for="kelompokPembaca3">Semua Umur</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Jenis Pustaka</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.jenis_pustaka" value="1" type="radio" id="jenisPustaka1" name="jenisPustaka"
                    class="custom-control-input">
                  <label class="custom-control-label" for="jenisPustaka1">Fiksi</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.jenis_pustaka" value="2" type="radio" id="jenisPustaka2"
                    name="jenisPustaka" class="custom-control-input">
                  <label class="custom-control-label" for="jenisPustaka2">Non Fiksi</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Kategori Jenis</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kategori_jenis" value="1" type="radio" id="kategoriJenis1"
                    name="kategoriJenis" class="custom-control-input">
                  <label class="custom-control-label" for="kategoriJenis1">Terjemahan</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kategori_jenis" value="2" type="radio" id="kategoriJenis2"
                    name="kategoriJenis" class="custom-control-input">
                  <label class="custom-control-label" for="kategoriJenis2">Non Terjemahan</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Terbitan</div>
              <div class="col-md-8">
                <div class="form-check">
                  <input v-model="isbn.terbitan" disabled value="1" type="checkbox" id="terbitan1"
                    name="terbitan" class="form-check-input">
                  <label class="form-check-label" for="terbitan1">Pemerintah</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Jenis Permohonan ISBN</div>
              <div class="col-md-8">
                <div class="d-flex">
                  <div class="custom-control custom-radio custom-control-inline flex-grow-1">
                    <input v-model="isbn.jenis_permohonan" value="lepas" type="radio" id="jenisPermohonan1"
                      name="jenisPermohonan" class="custom-control-input">
                    <label class="custom-control-label" for="jenisPermohonan1">
                      <span>Lepas</span>
                      <small class="text-muted d-block">Penerbit akan mendapatkan 1 nomor ISBN untuk setiap judul yang diminta.</small>
                    </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline flex-grow-1">
                    <input v-model="isbn.jenis_permohonan" value="jilid" type="radio" id="jenisPermohonan2"
                      name="jenisPermohonan" class="custom-control-input">
                    <label class="custom-control-label" for="jenisPermohonan2">
                      <span>Jilid</span>
                      <small class="text-muted d-block">Untuk permohonan jilid baru, penerbit akan menerima minimal 2 ISBN : yaitu 1 ISBN jilid lengkap, serta 1 ISBN yang spesifik untuk jilidnya.</small>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Apakah terdapat ilustrasi dalam terbitan Anda?</div>
              <div class="col-md-8">
                <div class="form-check">
                  <input v-model="isbn.ilustrasi" :true-value="1" :false-value="0" type="checkbox" id="ilustrasi"
                    name="ilustrasi" class="form-check-input">
                  <label class="form-check-label" for="ilustrasi">Ya, ada ilustrasi.</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Media</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.media" value="1" type="checkbox" id="media1" name="media" class="custom-control-input">
                  <label class="custom-control-label" for="media1">Buku</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.media" value="2" type="checkbox" id="media2" name="media" class="custom-control-input">
                  <label class="custom-control-label" for="media2">PDF</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.media" value="3" type="checkbox" id="media3" name="media" class="custom-control-input">
                  <label class="custom-control-label" for="media3">Epub</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.media" value="4" type="checkbox" id="media4" name="media" class="custom-control-input">
                  <label class="custom-control-label" for="media4">Audio Book (CD)</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.media" value="5" type="checkbox" id="media5" name="media" class="custom-control-input">
                  <label class="custom-control-label" for="media5">Audio Book (DVD)</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.media" value="6" type="checkbox" id="media6" name="media" class="custom-control-input">
                  <label class="custom-control-label" for="media6">Video</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">ISBN Susulan</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.isbn_susulan" value="Ya" type="radio" id="isbnSusulan1" name="isbnSusulan"
                    class="custom-control-input">
                  <label class="custom-control-label" for="isbnSusulan1">Ya</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.isbn_susulan" value="Tidak" type="radio" id="isbnSusulan2" name="isbnSusulan"
                    class="custom-control-input">
                  <label class="custom-control-label" for="isbnSusulan2">Tidak</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Pengajuan KDT</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.pengajuan_kdt" value="Ya" type="radio" id="pengajuanKDT1" name="pengajuanKDT"
                    class="custom-control-input">
                  <label class="custom-control-label" for="pengajuanKDT1">Ya</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.pengajuan_kdt" value="Tidak" type="radio" id="pengajuanKDT2" name="pengajuanKDT"
                    class="custom-control-input">
                  <label class="custom-control-label" for="pengajuanKDT2">Tidak</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Penelitian</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.penelitian" value="Ya" type="radio" id="penelitian1" name="penelitian" class="custom-control-input">
                  <label class="custom-control-label" for="penelitian1">Ya</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.penelitian" value="Tidak" type="radio" id="penelitian2" name="penelitian" class="custom-control-input">
                  <label class="custom-control-label" for="penelitian2">Tidak</label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 my-2">
            <div class="row">
              <div class="col-md-4">Kategori Buku</div>
              <div class="col-md-8">
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kategori_buku" value="Lepas" type="radio" id="kategoriBuku1" name="kategoriBuku"
                    class="custom-control-input">
                  <label class="custom-control-label" for="kategoriBuku1">Lepas</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input v-model="isbn.kategori_buku" value="Jilid" type="radio" id="kategoriBuku2" name="kategoriBuku"
                    class="custom-control-input">
                  <label class="custom-control-label" for="kategoriBuku2">Jilid</label>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-2">
            <div class="form-group">
              <label>Cover</label>
              <input type="hidden" class="form-control mb-2" placeholder="Preview URL" v-model="isbn.cover" />
              <small v-if="messageError.cover !== ''" class="text-danger d-block">{{ messageError.cover }}</small>
              <input @change="selectFile($event, 'cover')" type="file" accept="image/*" name="cover" id="cover" class="form-control" />
              <a v-if="isbn.cover" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${isbn.cover}`" target="_blank" class="my-2 d-block text-dark small" style="cursor: pointer"><i class="fa fa-image"></i> Lihat Cover</a>
              <div v-show="message.cover" class="mt-2 alert alert-success">
                  {{ message.cover }}
              </div>
              <div>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <button v-if="!loading.cover && !isbn.cover" @click="uploadingFile('cover')" type="button" class="btn btn-primary btn-block">
                      Upload
                    </button>
                    <button type="button" v-if="loading.cover" class="btn btn-primary btn-block">
                      <span class="spinner-border spinner-border-sm"></span>
                      Sedang upload..
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div class="col-md-12 my-2">
            <div class="row d-flex align-items-center">
              <div class="col-md-7">
                <div class="form-group">
                  <label>Buku Dummy (PDF) Maks 1,5 MB</label>
                  <input v-model="isbn.dummy" type="hidden" class="form-control" />
                  <small v-if="messageError.dummy !== ''" class="text-danger d-block">{{ messageError.dummy }}</small>
                  <input @change="selectFile($event, 'dummy')" type="file" class="form-control" :required="required.dummy" />
                  <a v-if="isbn.dummy" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${isbn.dummy}`" target="_blank" class="text-dark small my-2 d-block" style="cursor: pointer"><i class="fa fa-file-pdf"></i> Lihat Buku Dummy</a>
                </div>
                <div v-show="message.dummy" class="mt-2 alert alert-success">
                  {{ message.dummy }}
                </div>
                <button @click="uploadingFile('dummy')" v-if="!loading.dummy && !isbn.dummy" type="button" class="btn btn-primary btn-block">
                  Upload
                </button>
                <button type="button" v-if="loading.dummy" class="btn btn-primary btn-block">
                  <span class="spinner-border spinner-border-sm"></span>
                  Sedang diproses
                </button>
              </div>
            </div>
          </div>
         

          <div class="col-md-12 my-2">
            <div class="row d-flex align-items-center">
              <div class="col-md-7">
                <div class="form-group">
                  <label>Lampiran, Cover, Prelim, SKK & Surat Pengantar (PDF) Maks 1,5 MB</label>
                  <input v-model="isbn.lampiran" type="hidden" class="form-control" />
                  <small v-if="messageError.lampiran !== ''" class="text-danger d-block">{{ messageError.lampiran }}</small>
                  <input @change="selectFile($event, 'lampiran')" type="file" class="form-control" :required="required.lampiran" />
                  <a v-if="isbn.lampiran" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${isbn.lampiran}`" target="_blank" class="text-dark small my-2 d-block" style="cursor: pointer"><i class="fa fa-file-pdf"></i> Lihat PDF</a>
                </div>
                <div v-show="message.lampiran" class="mt-2 alert alert-success">
                  {{ message.lampiran }}
                </div>
                <button @click="uploadingFile('lampiran')" v-if="!loading.lampiran && !isbn.lampiran" type="button" class="btn btn-primary btn-block">
                  Upload
                </button>
                <button type="button" v-if="loading.lampiran" class="btn btn-primary btn-block">
                  <span class="spinner-border spinner-border-sm"></span>
                  Sedang diproses
                </button>
              </div>
            </div>
          </div>

          <!--
          <div class="col-md-12 my-2">
            <div class="row d-flex align-items-center">
              <div class="col-md-7">
                <div class="form-group">
                  <label>Prelim (PDF) Maks 1,5 MB</label>
                  <input v-model="isbn.prelim" type="hidden" class="form-control" />
                  <small v-if="messageError.prelim !== ''" class="text-danger d-block">{{ messageError.prelim }}</small>
                  <input @change="selectFile($event, 'prelim')" type="file" class="form-control" :required="required.prelim" />
                  <a v-if="isbn.prelim" :href="`https://static.buku.kemdikbud.go.id/uploader/files/${isbn.prelim}`" target="_blank" class="text-dark small my-2 d-block" style="cursor: pointer"><i class="fa fa-file-pdf"></i> Lihat PDF</a>
                </div>
                <div v-show="message.prelim" class="mt-2 alert alert-success">
                  {{ message.prelim }}
                </div>
                <button @click="uploadingFile('prelim')" v-if="!loading.prelim && !isbn.prelim" type="button" class="btn btn-primary btn-block">
                  Upload
                </button>
                <button type="button" v-if="loading.prelim" class="btn btn-primary btn-block">
                  <span class="spinner-border spinner-border-sm"></span>
                  Sedang diproses
                </button>
              </div>
            </div>
          </div> 
          -->

        </div>
        <div v-show="failedRequest" class="alert alert-warning alert-dismissible fade show" role="alert">
          Terjadi kesalahan teknis
          <button @click="failedRequest = false" type="button" class="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="text-right">
          <button v-if="$store.state.loadPage" class="btn btn-primary">
            <span class="spinner-border spinner-border-sm"></span>
            Sedang diproses..
          </button>
          <button v-else type="submit" class="btn btn-primary">Simpan</button>
        </div>
      </form>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-labelledby="successModalTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header p-0 border-0">
            <button class="btn  btn-sm" data-dismiss="modal"><i class="fa fa-x"></i></button>
          </div>
          <div class="modal-body text-center">
            <i class="fa fa-circle-check text-success h1"></i>
            <h4>Pengajuan ISBN berhasil dibuat</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      authors: [{
        role: 'penulis',
        value: ''
      }],
      failedRequest: false,
      isbn: {
        id: "",
        judul: "",
        kepengarangan: "",
        sinopsis: "",
        distributor: "",
        edisi: "",
        seri: "",
        cover: "",
        tahun_terbit: "",
        jumlah_halaman: "",
        tinggi_buku: "",
        dimensi: "",
        harga_buku: "",
        bulan_terbit: "01",
        tempat_terbit: "",
        kelompok_pembaca: "Anak",
        jenis_pustaka: "Fiksi",
        kategori_jenis: "Terjemahan",
        terbitan: "Pemerintah",
        ilustrasi: 0,
        jenis_permohonan: "lepas",
        media: [],
        isbn_susulan: false,
        pengajuan_kdt: "Ya",
        penelitian: "Ya",
        kategori_buku: "Lepas",
        lampiran: "",
        prelim: "",
        dummy: ""
      },
      required: {
        lampiran: false,
        prelim: false,
        cover: false,
        dummy: false,
      },
      loading: {
        lampiran: false,
        prelim: false,
        cover: false,
        dummy: false,
      },
      file: {
        lampiran: null,
        prelim: null,
        cover: null,
        dummy: null,
      },
      message: {
        lampiran: '',
        prelim: '',
        dummy: '',
        cover: ''
      },
      messageError: {
        lampiran: '',
        prelim: '',
        cover: '',
        dummy: '',
        kepengarangan: '', // add this
      },
    };
  },
  name: "ISBN Detail",
  computed: {
    previewImage() {
      if (this.isbn.cover != "") {
        return this.isbn.cover;
      } else {
        return "https://via.placeholder.com/240x300?text=Kover%20Buku"
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchDetailISBN",
      "updateDetailISBN",
      "uploadFile",
      "uploadGoogleDrive"
    ]),
    addAuthor() {
      this.authors.push({
        role: '',
        value: ''
      });
      this.updateKepengarangan();
    },
    removeAuthor(index) {
      if (this.authors.length > 1) {
        this.authors.splice(index, 1);
        this.updateKepengarangan();
      }
    },
    updateKepengarangan() {
      const formatted = this.authors.reduce((acc, curr) => {
        if (curr.role && curr.value) {
          acc.push({ [curr.role]: curr.value });
        }
        return acc;
      }, []);
      this.isbn.kepengarangan = JSON.stringify(formatted);
    },
    selectFile(e, state) {
      this.isbn[state] = '';
      this.message[state] = '';
      const file = e.target.files[0];
      this.file[state] = file;
    },
    uploadingFile(state) {
      if (this.file[state] === null) {
        this.messageError[state] = `File harus diisi`;
      } else {
        if (this.file[state].size >= 20971520) { // 20 MB = 20 * 1024 * 1024 bytes
            this.messageError[state] = "Ukuran file terlalu besar, maksimal 20 MB";
        } else {
          const uploader = this.uploadFile;
          this.loading[state] = true;
          this.messageError[state] = "";
          uploader(this.file[state])
            .then((res) => {
              if (res.status === "success" || res.status == 200) {
                this.isbn[state] = res.file;
                this.message[state] = state == 'cover' ? 'Berkas kover berhasil diunggah' : 'Berhasil diunggah, selanjutnya silahkan klik tombol simpan'
                this.file[state] = null;
              } else {
                this.messageError[state] = res.message;
              }
            })
            .catch((err) => {
              this.messageError[state] = "File gagal diupload";
              this.$store.state.loadUploadFile = false;
              console.log(err);
            })
            .finally(() => this.loading[state] = false);
        }
      }
    },
    setValue(isbn) {
      isbn = isbn || this.$store.state.detailISBN;
      
      // Handle kepengarangan data
      try {
        const kepengaraganArray = JSON.parse(isbn.kepengarangan);
        if (Array.isArray(kepengaraganArray)) {
          this.authors = kepengaraganArray.map(item => {
            const [[role, value]] = Object.entries(item);
            return { role, value };
          });
        }
      } catch (e) {
        // If parsing fails or kepengarangan is empty, keep the default author
        console.log('Failed to parse kepengarangan:', e);
      }

      this.isbn.id = isbn.id;
      this.isbn.judul = isbn.judul;
      this.isbn.kepengarangan = isbn.kepengarangan;
      this.isbn.sinopsis = isbn.sinopsis;
      this.isbn.distributor = isbn.distributor;
      this.isbn.edisi = isbn.edisi;
      this.isbn.seri = isbn.seri;
      this.isbn.cover = isbn.cover ?? "";
      this.isbn.tahun_terbit = isbn.tahun_terbit;
      this.isbn.jumlah_halaman = isbn.jumlah_halaman;
      this.isbn.tinggi_buku = isbn.tinggi_buku;
      this.isbn.dimensi = isbn.dimensi ?? "";
      this.isbn.harga_buku = isbn.harga_buku ?? "";
      this.isbn.bulan_terbit = isbn.bulan_terbit ?? "01";
      this.isbn.tempat_terbit = isbn.tempat_terbit ?? "";
      this.isbn.kelompok_pembaca = isbn.kelompok_pembaca ?? "1";
      this.isbn.jenis_pustaka = isbn.jenis_pustaka ?? "1";
      this.isbn.kategori_jenis = isbn.kategori_jenis ?? "1";
      this.isbn.terbitan = isbn.terbitan ?? "1";
      this.isbn.ilustrasi = isbn.ilustrasi ?? 0;
      this.isbn.jenis_permohonan = isbn.jenis_permohonan ?? "lepas";
      this.isbn.media = isbn.media.split(',') ?? "1";
      this.isbn.isbn_susulan = isbn.isbn_susulan;
      this.isbn.pengajuan_kdt = isbn.pengajuan_kdt;
      this.isbn.penelitian = isbn.penelitian;
      this.isbn.kategori_buku = isbn.kategori_buku;
      this.isbn.lampiran = isbn.lampiran ?? "";
      this.isbn.prelim = isbn.prelim ?? "";
      this.isbn.dummy = isbn.dummy ?? "";

      if(this.isbn.cover === "") this.required.cover = true;
      if(this.isbn.lampiran === "") this.required.lampiran = true;
      if(this.isbn.prelim === "") this.required.prelim = true;
      if(this.isbn.dummy === "") this.required.dummy = true;
    },
    updateISBN() {
      if (!this.authors.some(author => author.role && author.value)) {
        this.messageError.kepengarangan = "Minimal satu kepengarangan harus diisi";
        return;
      }
      
      if (this.isbn.cover === "") {
        this.messageError.cover = "Silahkan upload file cover terlebih dahulu";
      } else if(this.isbn.lampiran === "") {
        this.messageError.lampiran = "Silahkan upload file lampiran terlebih dahulu";
      } else if(this.isbn.dummy === "") {
        this.messageError.dummy = "Silahkan upload file dummy terlebih dahulu";
      } else {
        this.updateDetailISBN(this.isbn)
        .then(() => {
          this.$router.push("/user/pengajuan-isbn");
        })
        .catch(() => {
          this.failedRequest = true;
        });
      }
    },
  },
  beforeCreate() {
    const logged_in = localStorage.getItem("user");
    if (!logged_in) {
      this.$router.push("/login");
    }
    // Check role name
    const { role_name } = JSON.parse(localStorage.getItem('user'))
    if (role_name !== 'Satker') this.$router.push('/user/home')

  },
  created() {
    this.failedRequest = false;
    this.$store.state.detailISBN
      ? this.fetchDetailISBN(this.$route.params.id).then(this.setValue)
      : this.setValue();
  },
};
</script>